/* eslint-disable import/prefer-default-export */
import { ForwardRefExoticComponent } from "react";
import InternalInput, { InputProps } from "./Input";
import Button from "./InputButton";
import Quantity from "./InputQuantity";
import SendOTP from "./InputSendOTP";
import Trade from "./InputTrade";
type CompoundedComponent = ForwardRefExoticComponent<InputProps> & {
  SendOTP: typeof SendOTP;
  Button: typeof Button;
  Quantity: typeof Quantity;
  Trade: typeof Trade;
  // add more
};
const Input = InternalInput as CompoundedComponent;
Input.SendOTP = SendOTP;
Input.Button = Button;
Input.Quantity = Quantity;
Input.Trade = Trade;
export type { InputProps };
export default Input;